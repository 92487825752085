import React, { Fragment, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  SvgIcon,
  Button,
  Tooltip,
  Heading,
  Loader,
  useDidMount,
  useBoolean,
  clsx,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { EditControlsBar } from "./edit-controls-bar";
import { MeasurementTutorial } from "./measurement-tutorial";
import { Inspector } from "./inspector";
import { Navbar } from "./navbar";
import { editMediaStore } from "./edit-media.store";
import { EditMediaPageHistoryState } from "./edit-media.types";

import { ReactComponent as InfoSVG } from "src/external-ts/assets/images/info-grey.svg";
import styles from "./edit-media.module.css";
import { MediaTags } from "src/containers/media/media-tags";

export const EditMediaPage: React.FC = observer(() => {
  const { mediaId } = useParams<{ mediaId: string }>();

  const navigate = useNavigate();
  const location = useLocation();

  const { localeStore, mediaStore, userStore } = useStores();

  const isTutorialOpenBoolean = useBoolean(true);
  const [error, setError] = useState(false);

  const { renderer, isInMeasurement } = editMediaStore;

  const canvasWrapperRef = useRef<HTMLDivElement>(null);
  const tutorialButtonRef = useRef<HTMLButtonElement>(null);

  const handleGoBack = () => {
    mediaStore.setScrollRestoration(true);
    userStore.parentPostMessageClosed({ mediaId });
    navigate(-1);
  };

  useDidMount(() => {
    if (!canvasWrapperRef.current || !mediaId) return;
    editMediaStore
      .mount(canvasWrapperRef.current, mediaId, location.state as EditMediaPageHistoryState)
      .catch(() => setError(true));
    return () => editMediaStore.unmount();
  });

  return (
    <div className={styles.editMediaPageContainer} data-cy="media-edit-page">
      {editMediaStore.loading && (
        <Loader
          className={styles.globalOverlay}
          position="absolute"
          type="goo-loader"
          withOverlay
        />
      )}
      {error && (
        <div className={styles.globalOverlay}>
          <Heading tag="h2">{localeStore.t('common.webgl2["not-supported"]')}</Heading>
          <Button className={styles.goBackBtn} onClick={handleGoBack}>
            {localeStore.t('common.buttons["go-back"]')}
          </Button>
        </div>
      )}

      {!!renderer && !!editMediaStore.mainImageComponent && (
        <EditControlsBar onGoBack={handleGoBack} />
      )}

      <div className={styles.pageContentWrapper}>
        <Navbar />
        <Inspector />
        <div className={styles.right}>
          <div
            // className={clsx(styles.canvasWrapper, `${userStore.backgroundFill}Background`)}
            className={clsx(styles.canvasWrapper, "whiteBackground")}
            ref={canvasWrapperRef}
          />
          {isInMeasurement && (
            <Fragment>
              <Button
                className={styles.infoButton}
                appearance="secondaryOutlined"
                forwardRef={tutorialButtonRef}
                onClick={isTutorialOpenBoolean.trigger}
              >
                <SvgIcon icon={InfoSVG} />
              </Button>
              <Tooltip
                className={styles.measurementTutorialTooltip}
                position="topRight"
                offsetY={4}
                target={tutorialButtonRef}
                onClose={isTutorialOpenBoolean.setValue}
                isOpen={isTutorialOpenBoolean.value}
                disableOnOutsideClick
              >
                <MeasurementTutorial onClose={isTutorialOpenBoolean.setFalsy} />
              </Tooltip>
            </Fragment>
          )}
          {!editMediaStore.loading && (
            <MediaTags
              className={styles.mediaTags}
              metaData={editMediaStore.metaData}
              events={{
                transparentBackground: {
                  onStart: () => editMediaStore.chessGridComponentDisplay("initial"),
                  onEnd: () => editMediaStore.chessGridComponentDisplay("none"),
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default EditMediaPage;
